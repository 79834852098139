import React from 'react';
import { noop, trim, isEmpty } from 'lodash';
import { IconButton } from '@fluentui/react';

import Plot from './plot';
import DownloadCardForm from './DownloadCardForm';
import ImpactAssessment from './ImpactAssessment';
import PlotMessage from './FreeVersion/PlotMessage';
import { NotificationBellIcon } from '../amdc-app-frame/components/Topbar/NotificationBellIcon';
import Tabs from './Tabs/index.js';

import './buildingBlockStyles.css';
import { NiceScale } from './PlotView/nicescale.js';
import { version as appversion } from '../../version';
import * as TextMapping from '../utils/textMapping.js';
import Smile from './Smile/index.js';

const math = require('mathjs');

const colors = [
  'rgb(0, 156, 222)',
  'rgb(227, 0, 28)',
  'rgb(81, 173, 50)',
  'rgb(239, 150, 22)',
  'rgb(4, 69, 204)',
  'rgb(255, 163, 202)',
  'rgb(111, 47, 145)',
  'rgb(255, 82, 0)',
  'rgb(18, 131, 112)',
  'rgb(169, 0, 254)',
  'rgb(79, 192, 209)',
  'rgb(174, 98, 51)',
  'rgb(79, 209, 144)',
  'rgb(161, 0, 37)',
  'rgb(150, 150, 150)',
  'rgb(215, 192, 2)',
  'rgb(173, 135, 247)',
  'rgb(255, 44, 113)',
  'rgb(0, 170, 170)',
  'rgb(117, 49, 40)',
];

export function processFormat(format) {
  if (format) {
    return format;
  } else {
    return '';
  }
}

export function onRelayout(key, event, isLogX, isLogY, layoutMap, setLayoutMap) {
  if (event['xaxis.range[0]'] && event['xaxis.range[1]'] && event['yaxis.range[0]'] && event['yaxis.range[1]']) {
    setLayoutMap(
      new Map(
        layoutMap.set(key, {
          xaxis: [
            isLogX ? Math.pow(10, event['xaxis.range[0]']) : event['xaxis.range[0]'],
            isLogX ? Math.pow(10, event['xaxis.range[1]']) : event['xaxis.range[1]'],
          ],
          yaxis: [
            isLogY ? Math.pow(10, event['yaxis.range[0]']) : event['yaxis.range[0]'],
            isLogY ? Math.pow(10, event['yaxis.range[1]']) : event['yaxis.range[1]'],
          ],
        })
      )
    );
  }
}

export function onDoubleClick(key, layoutMap, setLayoutMap) {
  layoutMap.delete(key);
  setLayoutMap(new Map(layoutMap));
}

export function niceRange(values, islog) {
  let nice = new NiceScale().init(Math.min(...values), Math.max(...values), islog).get();

  return {
    range: [nice.min, nice.max],
    dtick: nice.spacing,
    ticks: nice.ticks,
  };
}

const niceRangeFixedTicks = (values, numticks, islog) => {
  let nice = new NiceScale().init(Math.min(...values), Math.max(...values), islog).get();
  if (nice.ticks < numticks) {
    nice.max += (numticks - nice.ticks) * nice.spacing;
    nice.ticks = numticks;
  }
  return {
    range: [nice.min, nice.max],
    dtick: nice.spacing,
    ticks: nice.ticks,
  };
};

function findPrePoint(xs, ys, tm) {
  let index = -1;
  for (let x of xs) {
    if (x <= tm) {
      index += 1;
    }
  }

  return [xs[index], ys[index]];
}

function findNextPoint(xs, ys, tm) {
  let index = 0;
  for (let x of xs) {
    if (x - tm >= 0) {
      return [x, ys[index]];
    }
    index += 1;
  }
  return [];
}

function linearInterpolation(x1, y1, x2, y2, x) {
  return y1 + ((x - x1) * (y2 - y1)) / (x2 - x1);
}

function modeSelector(type, x) {
  if (x.length === 1) {
    return 'markers';
  }
  switch (type) {
    case 'points':
    case 'symbols':
      return 'markers';
    case 'points+lines':
    case 'symbols+lines':
    case 'points+spline':
    case 'symbols+spline':
      return 'markers+lines';
    default:
      return 'lines';
  }
}
export function createMultipleDiagram(catalog, values) {
  let zerolinewidth = 1;
  let dias = [];
  let diagram = values;
  let traces = [];

  for (let curve of diagram.points) {
    let data = { x: [], y: [], z: [] };
    for (let point of curve.data) {
      data.x.push(point[0]);
      data.y.push(point[1]);
    }
    dias.push(data);
  }

  let allX = [];
  let numticks = 0;
  let connection = catalog.options.connection;
  let lineshape = connection.match(/spline/) ? 'spline' : 'linear';
  let isSymbols = connection.match(/symbols/);

  let layout = {};

  for (const [diaindex, dia] of dias.entries()) {
    let lineAttribute = {
      color: colors[diaindex % colors.length],
      shape: lineshape,
      smoothing: 1.3,
    };
    //numbers specify symbols -  100:outline-circle 101:outline-square - 0 default symbol filler circle
    let markerAttribute = {
      color: colors[diaindex % colors.length],
      symbol: isSymbols ? diaindex + 100 : 0,
    };
    if (diaindex === 0) {
      traces.push({
        x: dia.x,
        y: dia.y,
        name: dia.name,
        type: 'scatter',
        line: lineAttribute,
        mode: modeSelector(connection, dia.x),
        marker: markerAttribute,
      });
    } else if (diaindex === 1) {
      traces.push({
        x: dia.x,
        y: dia.y,
        name: dia.name,
        type: 'scatter',
        line: lineAttribute,
        yaxis: 'y2',
        mode: modeSelector(connection, dia.x),
        marker: markerAttribute,
      });
    } else if (diaindex === 2) {
      traces.push({
        x: dia.x,
        y: dia.y,
        type: 'scatter',
        name: dia.name,
        line: lineAttribute,
        yaxis: 'y3',
        mode: modeSelector(connection, dia.x),
        marker: markerAttribute,
      });
    }

    allX.push(...dia['x']);
    let isYLog = catalog['options']['scale']['y'][diaindex] === 'log';
    numticks = math.max(numticks, niceRange(dia['y'], isYLog)['ticks']);
  }

  let isXLog = catalog['options']['scale']['x'] === 'log';
  let ns = niceRange(allX, isXLog);
  let type = catalog['options']['scale']['x'];
  layout = {
    plot_bgcolor: 'white',
    showlegend: false,
    autosize: true,
    title: {
      text: diagram.name,
    },
    xaxis: {
      type: type,
      title: catalog['axes']['x'] + (catalog.unitsx ? ' [' + catalog.unitsx + ']' : ''),
      axislabel: catalog['axes']['x'],
      axisunit: catalog.unitsx ? catalog.unitsx : '',
      exponentformat: 'E',
      showline: true,
      showticklabel: true,
      showgrid: true,
      zeroline: true,
      zerolinecolor: 'rgb(224, 224, 224)',
      zerolinewidth: zerolinewidth,
      linecolor: 'rgb(82, 82, 82)',
      gridcolor: 'rgb(224, 224, 224)',
      linewidth: 1,
      gridwidth: 1,
      range: ns['range'],
      dtick: ns['dtick'],
      mirror: true,
      ticks: 'outside',
      ticklen: 8,
      tickcolor: 'white',
    },
  };

  for (const [diaindex, dia] of dias.entries()) {
    let isYLog = catalog['options']['scale']['y'][diaindex] === 'log';
    let ns = niceRangeFixedTicks(dia['y'], numticks, isYLog);
    let type = catalog['options']['scale']['y'][diaindex];
    let yaxis = {
      type: type,
      exponentformat: 'E',
      showgrid: true,
      zeroline: true,
      showline: true,
      showticklabels: true,
      zerolinecolor: 'rgb(224, 224, 224)',
      zerolinewidth: zerolinewidth,
      gridcolor: 'rgb(224, 224, 224)',
      linewidth: 1,
      gridwidth: 1,
      range: ns['range'],
      dtick: ns['dtick'],
      axislabel: catalog.axes.y[diaindex],
      axisunit: catalog.unitsy && catalog.unitsy[diaindex] ? catalog.unitsy[diaindex] : '',
      title: {
        text: catalog.axes.y[diaindex] + (catalog.unitsy && catalog.unitsy[diaindex] ? ' [' + catalog.unitsy[diaindex] + ']' : ''),
        standoff: 0,
      },
      mirror: true,
      automargin: true,
    };
    let newyaxis = JSON.parse(JSON.stringify(yaxis));
    newyaxis['titlefont'] = { color: colors[diaindex % colors.length] };
    newyaxis['tickfont'] = { color: colors[diaindex % colors.length] };
    if (diaindex === 0) {
      newyaxis['ticklabelposition'] = 'outside top';
      newyaxis['linecolor'] = 'rgb(82, 82, 82)';
      newyaxis['yanchor'] = 'top';
      layout.yaxis = newyaxis;
    } else if (diaindex === 1) {
      newyaxis['anchor'] = 'x';
      newyaxis['overlaying'] = 'y';
      newyaxis['side'] = 'right';
      newyaxis['linecolor'] = 'rgb(82, 82, 82)';
      layout.yaxis2 = newyaxis;
    } else if (diaindex === 2) {
      newyaxis['anchor'] = 'free';
      newyaxis['yanchor'] = 'bottom';
      newyaxis['overlaying'] = 'y';
      newyaxis['side'] = 'left';
      newyaxis['ticklabelposition'] = 'outside bottom';
      newyaxis['title']['standoff'] = 20;
      layout.yaxis3 = newyaxis;
    }
  }

  layout.title_text = catalog['name'];
  layout.title_x = 0.5;

  let plotData = {
    data: traces,
    layout: layout,
    orientation: catalog.options.format,
  };

  plotData.vkey = catalog.vkey;
  return plotData;
}

export function setupDiagram(catalog, value, layoutMap) {
  let zerolinewidth = 1;
  let data = [];
  let annotations = [];
  let shapes = [];

  let xValues = [];
  let yValues = [];

  let isLogX = catalog.options && catalog.options.scale && catalog.options.scale.x === 'log' ? true : false;
  let isLogY = catalog.options && catalog.options.scale && catalog.options.scale.y === 'log' ? true : false;
  let texts = new Set();

  let longestCurveName = 0;
  if (value && value.points) {
    value.points.forEach((curve, index) => {
      let x = [];
      let y = [];

      let linemarker = [];
      let xmarker = [];

      let anoText = {
        B: 'B: Break Point',
        Y: 'Y: Yield Point',
      };

      curve.data.forEach((point) => {
        x.push(point[0]);
        xValues.push(point[0]);
        yValues.push(point[1]);
        y.push(point[1]);
      });

      if (curve.ml) {
        let lastpoint = curve.data[curve.data.length - 1];
        linemarker.push({ text: curve.ml, x: lastpoint[0], y: lastpoint[1], name: curve.z });
        if (anoText[curve.ml]) {
          texts.add(anoText[curve.ml]);
        }
      }

      if (curve.mx) {
        for (let x of curve.mx) {
          for (const value of Object.values(x)) {
            xmarker.push(parseFloat(value));
          }
        }
      }

      if (curve.t) {
        for (let t of curve.t.split(';')) {
          texts.add(t);
        }
      }

      let nsY = niceRange(layoutMap.has(catalog._key) ? layoutMap.get(catalog._key).yaxis : y, isLogY);

      for (let ml of linemarker) {
        annotations.push({
          text: ml['text'],
          xref: 'x',
          yref: 'y',
          curveName: ml.name,
          x: catalog.options.scale.x !== 'log' ? ml['x'] : math.log10(ml['x']),
          y: catalog.options.scale.y !== 'log' ? ml['y'] : math.log10(ml['y']),
          xanchor: 'left',
          yanchor: 'middle',
          showarrow: false,
        });
      }

      for (let mx of xmarker) {
        let prePoint = findPrePoint(x, y, mx);
        let nextPoint = findNextPoint(x, y, mx);
        shapes.push({
          type: 'line',
          x0: mx,
          y0: nsY['range'][0],
          x1: mx,
          y1: linearInterpolation(prePoint[0], prePoint[1], nextPoint[0], nextPoint[1], mx),
          line: { dash: 'dot' },
        });
      }

      let connection = catalog.options.connection;
      let lineshape = connection.match(/spline/) ? 'spline' : 'linear';
      let isSymbols = connection.match(/symbols/);

      let lineAttribute = {
        shape: lineshape,
        smoothing: 1.3,
        color:
          catalog.options && catalog.options.colors && catalog.options.colors.length > index && catalog.options.colors[index]
            ? catalog.options.colors[index]
            : colors[index % colors.length],
      };

      if (catalog.options && catalog.options.dash && catalog.options.dash.length > index && catalog.options.dash[index]) {
        lineAttribute.dash = catalog.options.dash[index];
      }

      let markerAttribute = {
        color: colors[index % colors.length],
        symbol: isSymbols ? index + 100 : 0,
      };

      let curveName = (curve.z || curve.z === 0 ? curve.z : '') + (catalog.unitsz ? ' ' + catalog.unitsz : '');

      if (typeof curve.z2 !== 'undefined' && (curve.z2 + '').length)
        curveName += ', ' + ((curve.z + '').length ? curve.z2 : '') + (catalog.unitsz2 ? ' ' + catalog.unitsz2 : '');

      data.push({
        x: x,
        y: y,
        color: 'z',
        type: 'scatter',
        line: lineAttribute,
        curveName: curve.z,
        name: curveName,
        mode: modeSelector(connection, x),
        color_discrete_sequence: colors,
        marker: markerAttribute,
      });
    });
  }

  let type = catalog['options']['scale']['x'];
  let typeY = catalog['options']['scale']['y'];

  if (texts.size > 0) {
    annotations.push({
      text: [...texts].join('<br>'),
      align: 'left',
      xref: 'paper',
      yref: 'paper',
      xanchor: 'left',
      showarrow: false,
      x: 1.03,
      y: 0,
    });
  }

  //let niceRangeY = niceRange(layoutMap.has(catalog._key) ? (isLogY ? [Math.pow(10, layoutMap.get(catalog._key).yaxis[0]), Math.pow(10, layoutMap.get(catalog._key).yaxis[1])] : layoutMap.get(catalog._key).yaxis) : yValues, isLogY);
  //let niceRangeX = niceRange(layoutMap.has(catalog._key) ? (isLogX ? [Math.pow(10, layoutMap.get(catalog._key).xaxis[0]), Math.pow(10, layoutMap.get(catalog._key).xaxis[1])] : layoutMap.get(catalog._key).xaxis) : xValues, isLogX);

  let showlegend = value.points.length >= 1;

  if (data.length === 1 && (!data[0].name || isEmpty(data[0].name.trim()))) {
    showlegend = false;
  }

  for (let i = 0; i < data.length; i += 1) {
    data[i].name = data[i].name.trim();
    let newDataArray = data[i].name.split(', ');
    let updatedArray = [];
    for (let item of newDataArray) {
      if (item) {
        updatedArray.push(item.replace(/(.{13,}?)\s+/g, '$1<br>'));
      }
    }
    data[i].name = trim(updatedArray.join(',<br>'), '<br>');
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i].name) {
      let nameLines = data[i].name.split('<br>');
      if (nameLines) {
        for (let line of nameLines) {
          if (line.length > longestCurveName) {
            longestCurveName = line.length;
          }
        }
      }
    }
  }

  let plotData = {
    data: data,
    layout: {
      title: {
        text: value.name,
        font: {
          size: 14,
        },
      },
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        title: '',
        range: niceRange(layoutMap.has(catalog._key) ? layoutMap.get(catalog._key).xaxis : xValues, isLogX).range,
        dtick: niceRange(layoutMap.has(catalog._key) ? layoutMap.get(catalog._key).xaxis : xValues, isLogX).dtick,
        type: type,
        exponentformat: 'E',
        showline: true,
        showticklabels: true,
        showgrid: true,
        zeroline: true,
        zerolinecolor: '#DDDDDD',
        zerolinewidth: zerolinewidth,
        linecolor: 'rgb(82, 82, 82)',
        gridcolor: 'rgb(224, 224, 224)',
        linewidth: 1,
        gridwidth: 1,
        mirror: true,
      },
      yaxis: {
        title: '',
        range: niceRange(layoutMap.has(catalog._key) ? layoutMap.get(catalog._key).yaxis : yValues, isLogY).range,
        dtick: niceRange(layoutMap.has(catalog._key) ? layoutMap.get(catalog._key).yaxis : yValues, isLogY).dtick,
        //tickfont: { color: 'red' },
        type: typeY,
        exponentformat: 'E',
        showgrid: true,
        zeroline: true,
        showline: true,
        showticklabels: true,
        zerolinecolor: '#DDDDDD',
        zerolinewidth: zerolinewidth,
        linecolor: 'rgb(82, 82, 82)',
        gridcolor: 'rgb(224, 224, 224)',
        linewidth: 1,
        gridwidth: 1,
        mirror: true,
      },
      shapes: shapes,
      annotations: annotations,
      showlegend: showlegend,
      markers: false,
    },
    orientation: catalog.options.format,
    legendLength: longestCurveName,
  };

  let key = 'xaxis';
  plotData.layout[key].title = { text: catalog.axes.x + (catalog.unitsx ? ' [' + catalog.unitsx + ']' : '') };
  plotData.layout[key].axislabel = catalog.axes.x;
  plotData.layout[key].axisunit = catalog.unitsx ? catalog.unitsx : '';
  plotData.layout[key].type = catalog.options.scale.x;
  plotData.layout[key].exponentformat = 'E';

  key = 'yaxis';
  plotData.layout[key].title = { text: catalog.axes.y + (catalog.unitsy ? ' [' + catalog.unitsy + ']' : '') }; //font: { color: 'red' } };
  plotData.layout[key].axislabel = catalog.axes.y;
  plotData.layout[key].axisunit = catalog.unitsy ? catalog.unitsy : '';
  plotData.layout[key].type = catalog.options.scale.y;
  plotData.layout[key].exponentformat = 'E';

  plotData.vkey = catalog.vkey;

  return plotData;
}

export function processSubstitution(content, data, ranges, appContent, texts) {
  if (content && typeof content === 'string') {
    //content = content.replace(/\{%[™®\w\s:\-|=&]+%\}/g, function (all) {
    content = content.replace(/\{%.*%\}/g, function (all) {
      let toReplace = all.split('{%').join('').split('%}').join('');

      if (toReplace.startsWith('range:min:') && ranges) {
        let spt = parseInt(toReplace.replace('range:min:', ''));
        if (ranges.Spt && ranges.Spt[spt]) {
          return math.format(ranges.Spt[spt][0], 3).replace('e+', 'E').replace('e-', 'E-');
        } else {
          return all;
        }
      } else if (toReplace.startsWith('range:max:') && ranges) {
        let spt = parseInt(toReplace.replace('range:max:', ''));
        if (ranges.Spt && ranges.Spt[spt]) {
          return math.format(ranges.Spt[spt][1], 3).replace('e+', 'E').replace('e-', 'E-');
        } else {
          return all;
        }
      } else if (toReplace.startsWith('action:')) {
        let actionData = toReplace.replace('action:', '').split('|');
        if (actionData && actionData.length > 2) {
          let action = actionData[0];
          let argument = actionData[1];
          let actionText = actionData[2];

          if (action === 'bbpagemodal') {
            return `<a href='#' class="linkText" onclick="bbPageModal(event, '${argument}')">${actionText}</a>`;
          } else if (action === 'datasheet') {
            let textArgs = actionText.split('&');
            let text = textArgs[0];
            let type = textArgs[1];

            return `<a href='#' class="linkText" onclick="showDatasheet(event, '${argument}', '${type}')">${text}</a>`;
          }
        }
      } else if (toReplace === 'appversion') {
        return appversion;
      } else if (toReplace === 'useremail') {
        return appContent?.user?.email;
      } else if (toReplace === 'displayname') {
        return appContent?.user?.displayName;
      } else if (toReplace === 'username') {
        return appContent?.user?.username;
      } else if (toReplace === 'license') {
        let license = appContent.user && appContent.user.license && !isEmpty(appContent.user.license) ? appContent.user.license[0] : '';
        let username = appContent.user && appContent.user.username ? appContent.user.username : '';
        if (!license) license = username === 'freeuser' ? 'freeversion' : 'nolicense';
        let licensename = TextMapping.getUIText(license, texts, null, '');
        let licname = licensename ? TextMapping.getUIText(TextMapping.UI_TEXT_LICENSENAME, texts, { licensename: licensename }, '') : '';

        return licname;
      }
      return all;
    });

    content = content.replace(/%(?![0-9A-Fa-f]{2})[^%]+%/g, function (all) {
      return data ? data[all.split('%').join('')] || '' : '';
    });
  }

  return content;
}

export function processBuildingBlock({
  buildingBlock,
  body,
  windowSize,
  processCheckbox = noop,
  processIcon = noop,
  handleToggler = noop,
  collapsedVkeys = [],
  onTabClick = noop,
  getActiveTab = null,
  canAccess = false,
  onDownloadClick = noop,
  handleTextAction = noop,
  data,
  layoutMap = new Map(),
  setLayoutMap = noop,
  handleContactUsClick,
  handleNoDriveLicenseClick,
  webSocket = null,
  addSearch = noop,
  addCompare = noop,
  downloadPDF = noop,
  downloadXLS = noop,
  addStyle = noop,
  ranges = null,
  handleWaffleButtonClick,
  handleNotificationBellClick,
  handleUserButtonClick,
  addFeedback = noop,
  addBlocksPage = noop,
  appContent,
  texts,
  addTab = noop,
}) {
  if (buildingBlock.data && buildingBlock.data.breakpoints) {
    const { width, height } = windowSize;

    let foundMatch = false;
    let defaultValue = null;

    for (let [key, value] of Object.entries(buildingBlock.data.breakpoints)) {
      let parts = key.split('-');

      if (key === 'default') {
        defaultValue = value;
      }
      if (parts && parts.length === 3) {
        const operators = {
          lt: function (a, b) {
            return a < b;
          },
          gt: function (a, b) {
            return a > b;
          },
        };

        let comparison = null;

        if (parts[0] === 'w') {
          comparison = width;
        } else if (parts[0] === 'h') {
          comparison = height;
        }

        if (operators[parts[1]] && operators[parts[1]](comparison, parts[2])) {
          foundMatch = true;
          buildingBlock.data = { ...buildingBlock.data, ...value };
        }
      }
    }

    if (!foundMatch && defaultValue) {
      buildingBlock.data = { ...buildingBlock.data, ...defaultValue };
    }
  }
  if (buildingBlock.type === 'title') {
    if (buildingBlock.data.action) {
      body.push(handleTextAction(buildingBlock.data.content, buildingBlock.data.action, buildingBlock.data.format));
    } else {
      body.push(<div className={buildingBlock.data.format}>{buildingBlock.data.content}</div>);
    }
  } else if (buildingBlock.type === 'layout') {
    let childBody = [];
    for (let child of buildingBlock.data.content) {
      processBuildingBlock({
        buildingBlock: child,
        body: childBody,
        windowSize,
        processCheckbox,
        processIcon,
        handleToggler,
        collapsedVkeys,
        onTabClick,
        getActiveTab,
        canAccess,
        onDownloadClick,
        handleTextAction,
        data,
        layoutMap,
        setLayoutMap,
        handleContactUsClick,
        handleNoDriveLicenseClick,
        webSocket,
        addSearch,
        addCompare,
        downloadPDF,
        downloadXLS,
        addStyle,
        ranges,
        handleWaffleButtonClick,
        handleNotificationBellClick,
        handleUserButtonClick,
        addFeedback,
        addBlocksPage,
        appContent,
        texts,
        addTab,
      });
    }

    if (buildingBlock.data.collapsible === true) {
      body.push(
        <div data-testid={`building-block-layout-${buildingBlock.data.name}`} className={processFormat(buildingBlock.data.format)}>
          <IconButton
            style={{ width: 'auto', height: 'auto' }}
            iconProps={{ iconName: collapsedVkeys.includes(buildingBlock.data.uuid) ? 'RightSmall' : 'DownSmall' }}
            onClick={() => handleToggler(buildingBlock.data.uuid, !collapsedVkeys.includes(buildingBlock.data.uuid))}
            title="Close"
          />
          <button
            className="bodyText datasheetText"
            data-testid={`building-block-collapsebutton-${buildingBlock.data.name}`}
            style={{ background: 'none', border: 'none', margin: 0, padding: 0 }}
            onClick={() => handleToggler(buildingBlock.data.uuid, !collapsedVkeys.includes(buildingBlock.data.uuid))}
          >
            {buildingBlock.data.name}
          </button>
        </div>
      );
    } else if (buildingBlock.data.name) {
      body.push(
        <div data-testid={`building-block-layout-${buildingBlock.data.name}`} className={processFormat(buildingBlock.data.format)}>
          {buildingBlock.data.name}
        </div>
      );
    }

    if (buildingBlock.data.collapsible !== true || !collapsedVkeys.includes(buildingBlock.data.uuid)) {
      body.push(
        <div
          data-testid={`building-block-layout-${buildingBlock.data.name}`}
          className={
            buildingBlock.data.collapsible === true
              ? 'margin-left-30 ' + processFormat(buildingBlock.data.format)
              : processFormat(buildingBlock.data.format)
          }
        >
          {childBody}
        </div>
      );
    }
  } else if (buildingBlock.type === 'image') {
    let imgProps = {};
    if (!isEmpty(buildingBlock.data.src)) {
      imgProps.src = '/mmdc/' + buildingBlock.data.src;
    }
    body.push(
      <img
        data-testid={`building-block-image-${buildingBlock.data.name}`}
        className={processFormat(buildingBlock.data.format)}
        {...imgProps}
        alt={buildingBlock.data.alt}
      />
    );
  } else if (buildingBlock.type === 'component') {
    if (buildingBlock.data.style === 'PlotMessage') {
      body.push(<PlotMessage data-testid={`building-block-plot-message`} handleContactUsClick={handleContactUsClick} />);
    }
  } else if (buildingBlock.type === 'list') {
    let divClass = '';
    if (buildingBlock.data.format) {
      divClass = processFormat(buildingBlock.data.format);
    }

    if (buildingBlock.data.name) {
      body.push(
        <div data-testid={`building-block-list-${buildingBlock.data.name}`} className={processFormat(buildingBlock.data.headerformat)}>
          {buildingBlock.data.name}
        </div>
      );
    }

    if (buildingBlock.data.style === 'concat') {
      let hasContent = false;
      let substitutedContents = [];
      for (let content of buildingBlock.data.content) {
        let result = processSubstitution(content, data, ranges, appContent, texts);

        if (!isEmpty(result)) {
          substitutedContents.push(result);
          hasContent = true;
        }
      }

      if (hasContent) {
        if (buildingBlock.data.html) {
          body.push(
            <div
              data-testid={`building-block-concat-${buildingBlock.data.name}`}
              dangerouslySetInnerHTML={{ __html: substitutedContents.join(buildingBlock.data.separator) }}
              className={divClass}
            />
          );
        } else {
          body.push(
            <div data-testid={`building-block-concat-${buildingBlock.data.name}`} className={divClass}>
              {substitutedContents.join(buildingBlock.data.separator)}
            </div>
          );
        }
      }
    } else {
      let content = buildingBlock.data.content.map((item) => {
        if (typeof item === 'object' && item !== null && item.type === 'checkbox') {
          return processCheckbox(item.data.action, processFormat(buildingBlock.data.format));
        } else if (typeof item === 'object' && item != null && item.type === 'icon') {
          return processIcon(item.data.name, item.data.action, data);
        } else if (typeof item === 'object' && item !== null && item.type === 'shape') {
          return (
            <div>
              <div
                data-testid={`building-block-shape-${buildingBlock.data.name}`}
                className={item.data.shape}
                style={{ backgroundColor: item.data.color }}
              ></div>
              {item.data.value}
            </div>
          );
        }

        return <div>{item}</div>;
      });

      body.push(<div className={divClass}>{content}</div>);
    }
  } else if (buildingBlock.type === 'checkbox') {
    body.push(processCheckbox(buildingBlock.data.action, processFormat(buildingBlock.data.format)));
  } else if (buildingBlock.type === 'table') {
    if (buildingBlock.data.collapsible === true) {
      let headerIcons = null;
      if (buildingBlock.data.headericons) {
        headerIcons = [];
        for (let headerIcon of buildingBlock.data.headericons) {
          if (headerIcon.action === 'bbpagemodal') {
            headerIcons.push(
              <IconButton
                style={{ width: 'auto', height: 'auto' }}
                iconProps={{ iconName: headerIcon.icon }}
                onClick={() => window.bbPageModal(null, 'page=' + headerIcon.parameter.page)}
                title="Close"
              />
            );
          }
        }
      }
      body.push(
        <div data-testid={`building-block-table-${buildingBlock.data.name}`} className={processFormat(buildingBlock.data.headerformat)}>
          <IconButton
            style={{ width: 'auto', height: 'auto' }}
            iconProps={{ iconName: collapsedVkeys.includes(buildingBlock.data.uuid) ? 'RightSmall' : 'DownSmall' }}
            onClick={() => handleToggler(buildingBlock.data.uuid, !collapsedVkeys.includes(buildingBlock.data.uuid))}
            title="Close"
          />
          <button
            className="bodyText datasheetText"
            style={{ background: 'none', border: 'none', margin: 0, padding: 0 }}
            onClick={() => handleToggler(buildingBlock.data.uuid, !collapsedVkeys.includes(buildingBlock.data.uuid))}
          >
            {buildingBlock.data.name}
          </button>
          {headerIcons}
        </div>
      );
    } else if (buildingBlock.data.name) {
      body.push(
        <div data-testid={`building-block-layout-${buildingBlock.data.name}`} className={processFormat(buildingBlock.data.headerformat)}>
          {buildingBlock.data.name}
        </div>
      );
    }

    let rows = [];
    // rows header
    if (buildingBlock.data.columnnames && Array.isArray(buildingBlock.data.columnnames)) {
      let columns = [];
      buildingBlock.data.columnnames.forEach((columnData, index) => {
        let column = (
          <div
            className={processFormat(
              buildingBlock.data.columnnameformat ? buildingBlock.data.columnnameformat[index] : buildingBlock.data.columnformat[index]
            )}
          >
            {columnData}
          </div>
        );
        columns.push(column);
      });
      rows.push(
        <div data-testid="building-block-table" className={processFormat(buildingBlock.data.rowformat)}>
          {columns}
        </div>
      );
    }
    // rows data content
    for (let rowData of buildingBlock.data.content) {
      let columns = [];
      if (rowData && Array.isArray(rowData))
        rowData.forEach((columnData, index) => {
          if (Array.isArray(columnData)) {
            let column = <div className={processFormat(buildingBlock.data.columnformat[index])}>{columnData.join(' > ')}</div>;
            columns.push(column);
          }
          if (typeof columnData !== 'object') {
            let column;
            if (!buildingBlock.data.html || !buildingBlock.data.html[index]) {
              let columnSub = processSubstitution(columnData, data, ranges, appContent, texts);

              column = <div className={processFormat(buildingBlock.data.columnformat[index])}>{columnSub}</div>;
              columns.push(column);
            } else {
              column = (
                <div
                  className={processFormat(buildingBlock.data.columnformat[index])}
                  dangerouslySetInnerHTML={{ __html: processSubstitution(columnData, data, ranges, appContent, texts) }}
                />
              );
              columns.push(column);
            }
          } else {
            if (columnData.html) {
              columns.push(
                <div
                  className={processFormat(buildingBlock.data.columnformat[index])}
                  dangerouslySetInnerHTML={{ __html: columnData.html }}
                />
              );
            }
          }
        });

      rows.push(
        <div data-testid="building-block-table" className={processFormat(buildingBlock.data.rowformat)}>
          {columns}
        </div>
      );
    }

    if (buildingBlock.data.collapsible !== true || !collapsedVkeys.includes(buildingBlock.data.uuid)) {
      body.push(
        <div
          data-testid={`building-block-layout-${buildingBlock.data.name}`}
          className={
            buildingBlock.data.collapsible === true
              ? 'margin-left-30 ' + processFormat(buildingBlock.data.format)
              : processFormat(buildingBlock.data.format)
          }
        >
          {rows}
        </div>
      );
    }
  } else if (buildingBlock.type === 'paragraph') {
    if (!buildingBlock.data.html) {
      body.push(
        <p data-testid={`building-block-paragraph`} className={processFormat(buildingBlock.data.format)}>
          {processSubstitution(buildingBlock.data.content, data, ranges, appContent, texts)}
        </p>
      );
    } else {
      body.push(
        <div
          data-testid={`building-block-paragraph-html`}
          className={processFormat(buildingBlock.data.format)}
          dangerouslySetInnerHTML={{ __html: processSubstitution(buildingBlock.data.content, data, ranges, appContent, texts) }}
        />
      );
    }
  } else if (buildingBlock.type === 'search') {
    addSearch(body);
  } else if (buildingBlock.type === 'button') {
    let childBody = [];
    for (let child of buildingBlock.data.content) {
      processBuildingBlock({
        buildingBlock: child,
        body: childBody,
        windowSize,
        processCheckbox,
        processIcon,
        handleToggler,
        collapsedVkeys,
        onTabClick,
        getActiveTab,
        tabOrientation: buildingBlock.data.orientation,
        canAccess,
        onDownloadClick,
        handleTextAction,
        data,
        layoutMap,
        setLayoutMap,
        handleContactUsClick,
        handleNoDriveLicenseClick,
        webSocket,
        addSearch,
        addCompare,
        downloadPDF,
        downloadXLS,
        addStyle,
        ranges,
        handleWaffleButtonClick,
        handleNotificationBellClick,
        handleUserButtonClick,
        addFeedback,
        addBlocksPage,
        appContent,
        texts,
        addTab,
      });
    }

    body.push(
      <button
        style={{ background: 'none', border: 'none', margin: 0, padding: 0 }}
        className={processFormat(buildingBlock.data.format)}
        onClick={() => {
          window.location.href = buildingBlock.data.href;
        }}
      >
        {childBody}
      </button>
    );
  } else if (buildingBlock.type === 'tabs') {
    body.push(
      <Tabs
        orientation={buildingBlock.data.orientation || 'horizontal'}
        format={processFormat(buildingBlock.data.format)}
        name={buildingBlock.data.name}
        id={buildingBlock.data.id}
        breakpoint={buildingBlock.data.breakpoint}
        onTabClick={onTabClick}
        getActiveTab={getActiveTab}
        childContent={buildingBlock.data.content}
        windowSize={windowSize}
        processCheckbox={processCheckbox}
        processIcon={processIcon}
        handleToggler={handleToggler}
        collapsedVkeys={collapsedVkeys}
        canAccess={canAccess}
        onDownloadClick={onDownloadClick}
        handleTextAction={handleTextAction}
        data={data}
        layoutMap={layoutMap}
        setLayoutMap={setLayoutMap}
        handleContactUsClick={handleContactUsClick}
        handleNoDriveLicenseClick={handleNoDriveLicenseClick}
        webSocket={webSocket}
        addSearch={addSearch}
        addCompare={addCompare}
        downloadPDF={downloadPDF}
        downloadXLS={downloadXLS}
        addStyle={addStyle}
        ranges={ranges}
        handleWaffleButtonClick={handleWaffleButtonClick}
        handleNotificationBellClick={handleNotificationBellClick}
        handleUserButtonClick={handleUserButtonClick}
        addFeedback={addFeedback}
        addBlocksPage={addBlocksPage}
        appContent={appContent}
        texts={texts}
      />
    );
  } else if (buildingBlock.type === 'tab') {
    let childBody = [];

    if (buildingBlock.data.content) {
      for (let child of buildingBlock.data.content) {
        processBuildingBlock({
          buildingBlock: child,
          body: childBody,
          windowSize,
          processCheckbox,
          processIcon,
          handleToggler,
          collapsedVkeys,
          onTabClick,
          getActiveTab,
          canAccess,
          onDownloadClick,
          handleTextAction,
          data,
          layoutMap,
          setLayoutMap,
          handleContactUsClick,
          handleNoDriveLicenseClick,
          webSocket,
          addSearch,
          addCompare,
          downloadPDF,
          downloadXLS,
          addStyle,
          ranges,
          handleWaffleButtonClick,
          handleNotificationBellClick,
          handleUserButtonClick,
          addFeedback,
          addBlocksPage,
          appContent,
          texts,
          addTab,
        });
      }
    }

    addTab(body, buildingBlock, childBody);
  } else if (buildingBlock.type === 'diagram') {
    if (buildingBlock.data.value && buildingBlock.data.value.length > 0 && buildingBlock.data.value[0].points) {
      let plotData = null;
      if (Array.isArray(buildingBlock.data.catalog.axes.y)) {
        plotData = createMultipleDiagram(buildingBlock.data.catalog, buildingBlock.data.value[0]);
      } else {
        plotData = setupDiagram(buildingBlock.data.catalog, buildingBlock.data.value[0], layoutMap);
      }

      let comment = '';

      if (buildingBlock.data.value[0] && buildingBlock.data.value[0].comment) {
        comment = (
          <div
            style={{ flexGrow: 0, flexShrink: 0, padding: '10px' }}
            dangerouslySetInnerHTML={{ __html: buildingBlock.data.value[0].comment }}
          ></div>
        );
      }
      body.push(
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Plot
            style={{ flexGrow: 1, flexShrink: 1 }}
            comment={comment}
            name={'diagram'}
            id={buildingBlock.data.value.length > 0 ? buildingBlock.data.value[0]._key : ''}
            data-testid={`building-block-diagram-${buildingBlock.data.name}`}
            plotData={plotData}
            downloadPDF={downloadPDF}
            onRelayout={(event) =>
              onRelayout(
                buildingBlock.data.catalog._key,
                event,
                buildingBlock.data.catalog.options.scale.x === 'log',
                buildingBlock.data.catalog.options.scale.y === 'log',
                layoutMap,
                setLayoutMap
              )
            }
            onDoubleClick={() => onDoubleClick(buildingBlock.data.catalog._key, layoutMap, setLayoutMap)}
          />
        </div>
      );
    }
  } else if (buildingBlock.type === 'caeexport') {
    body.push(
      <DownloadCardForm
        data-testid="download-card-form"
        details={buildingBlock.data}
        canAccess={canAccess}
        onDownloadClick={onDownloadClick}
        webSocket={webSocket}
        handleContactUsClick={handleContactUsClick}
        handleNoDriveLicenseClick={handleNoDriveLicenseClick}
      />
    );
  } else if (buildingBlock.type === 'impactassessment') {
    let data = buildingBlock.data;
    if (isEmpty(data)) {
      data = 'blank';
    }

    let childBody = [];

    if (buildingBlock.data.content) {
      for (let child of buildingBlock.data.content) {
        processBuildingBlock({
          buildingBlock: child,
          body: childBody,
          windowSize,
          processCheckbox,
          processIcon,
          handleToggler,
          collapsedVkeys,
          onTabClick,
          getActiveTab,
          canAccess,
          onDownloadClick,
          handleTextAction,
          data,
          layoutMap,
          setLayoutMap,
          handleContactUsClick,
          handleNoDriveLicenseClick,
          webSocket,
          addSearch,
          addCompare,
          downloadPDF,
          downloadXLS,
          addStyle,
          ranges,
          handleWaffleButtonClick,
          handleNotificationBellClick,
          handleUserButtonClick,
          addFeedback,
          addBlocksPage,
          appContent,
          texts,
          addTab,
        });
      }
    }

    body.push(
      <ImpactAssessment
        data-testid="impact-assessment-form"
        details={data}
        canAccess={canAccess}
        handleContactUsClick={handleContactUsClick}
      >
        {childBody}
      </ImpactAssessment>
    );
  } else if (buildingBlock.type === 'string') {
    body.push(
      <div className={processFormat(buildingBlock.data.format)}>
        {processSubstitution(buildingBlock.data.content, data, ranges, appContent, texts)}
      </div>
    );
  } else if (buildingBlock.type === 'compare') {
    addCompare(body, buildingBlock.data.ids);
  } else if (buildingBlock.type === 'style') {
    addStyle(buildingBlock.data.id, buildingBlock.data.style);
  } else if (buildingBlock.type === 'wafflemenu') {
    body.push(<IconButton iconProps={{ iconName: 'waffle' }} onClick={handleWaffleButtonClick} />);
  } else if (buildingBlock.type === 'notificationbell') {
    body.push(
      <div onClick={handleNotificationBellClick}>
        <NotificationBellIcon />
      </div>
    );
  } else if (buildingBlock.type === 'userbutton') {
    body.push(<IconButton className={buildingBlock.data.format} iconProps={{ iconName: 'contact' }} onClick={handleUserButtonClick} />);
  } else if (buildingBlock.type === 'icon') {
    body.push(processIcon(buildingBlock.data.name, buildingBlock.data.action, buildingBlock.data));
  } else if (buildingBlock.type === 'feedback') {
    addFeedback(body);
  } else if (buildingBlock.type === 'blockspage') {
    addBlocksPage(body, buildingBlock.data.page);
  } else if (buildingBlock.type === 'smiles') {
    body.push(
      <Smile
        options={buildingBlock.data.options}
        value={buildingBlock.data.value}
        format={processFormat(buildingBlock.data.format)}
        id={buildingBlock.data.id}
      />
    );
  }
}
