import { processSubstitution } from '../components/processBuildingBlock';

export const UI_TEXT_FILTERS = 'filters';
export const UI_TEXT_SEARCH = 'search';
export const UI_TEXT_NEW_SEARCH = 'newsearch';
export const UI_TEXT_COMPARE = 'compare';
export const UI_TEXT_TABLE = 'table';
export const UI_TEXT_TILES = 'tiles';
export const UI_TEXT_CLEAR_SELECTIONS = 'clearselections';
export const UI_TEXT_CLEAR_POLAR_SELECTIONS = 'clearpolarselections';
export const UI_TEXT_CLEAR_CUSTOMIZE_SELECTIONS = 'clearcustomizeselections';
export const UI_TEXT_CUSTOMIZE_TABLE = 'customizetable';
export const UI_TEXT_USER_PREFERENCES = 'preferences';
export const UI_TEXT_DOWNLOAD_TO_OTHER_APP = 'downloadtootherapp';
export const UI_TEXT_SAVE_TO_MY_MATERIALS = 'savetomymaterials';
export const UI_TEXT_DOWNLOAD = 'download';
export const UI_TEXT_SELECT_MOISTURE_STATE = 'selectmoisturestate';
export const UI_TEXT_MOISTURE_STATE = 'moisturestate';
export const UI_TEXT_REDUCTION_FACTOR_PLACEHOLDER = 'reductionfactorplaceholder';
export const UI_TEXT_MODEL = 'model';
export const UI_TEXT_SELECT_MODEL = 'selectmodel';
export const UI_TEXT_SOFTWARE = 'software';
export const UI_TEXT_SELECT_SOFTWARE = 'selectsoftware';
export const UI_TEXT_UNIT = 'unit';
export const UI_TEXT_SELECT_UNIT = 'selectunit';
export const UI_TEXT_ADVANCED_SEARCH = 'advancedsearch';
export const UI_TEXT_FIELD = 'field';
export const UI_TEXT_OPERATOR = 'operator';
export const UI_TEXT_VALUE = 'value';
export const UI_TEXT_SAVE = 'save';
export const UI_TEXT_SAVE_AS = 'saveas';
export const UI_TEXT_LOAD_CRITERIA = 'loadcriteria';
export const UI_TEXT_CLEAR_SEARCH = 'clearsearch';
export const UI_TEXT_VISIT = 'visit';
export const UI_TEXT_SELECT_CATEGORY = 'selectcategory';
export const UI_TEXT_FILTER_BY_PROPERTY_NAME = 'filterbypropertyname';
export const UI_TEXT_SELECT_LANGUAGE = 'selectlanguage';
export const UI_TEXT_LANGUAGE = 'language';
export const UI_TEXT_APPLY = 'apply';
export const UI_TEXT_CANCEL = 'cancel';
export const UI_TEXT_CUSTOMIZE_COLUMNS = 'customizecolumns';
export const UI_TEXT_REORDER_COLUMNS = 'reordercolumns';
export const UI_TEXT_CHART = 'chart';
export const UI_TEXT_DATA = 'data';
export const UI_TEXT_SHOW_DIFFERENCES_ONLY = 'showdifferencesonly';
export const UI_TEXT_MANAGE_CONTENT = 'managecontent';
export const UI_TEXT_CONTENT_MANAGEMENT_TOOL = 'contentmanagementtool';
export const UI_TEXT_LOADING_CONTENTS = 'loadingcontents';
export const UI_TEXT_UPGRADE_TO_PREMIUM = 'upgradetopremium';
export const UI_TEXT_FREE_VERSION = 'freeversion';
export const UI_TEXT_LICENSENAME = 'licensename';
export const UI_TEXT_LICENSENAMEDRAWER = 'licensenamedrawer';
export const UI_TEXT_SELECT_A_PLOT = 'selectaplot';
export const UI_TEXT_NO_PLOT_FOUND_FOR = 'noplotfoundfor';
export const UI_TEXT_CLEAR_ALL_FILTERS = 'clearallfilters';
export const UI_TEXT_CONTACT_US = 'contactus';
export const UI_TEXT_FREE_VERSION_COMPARE_MESSAGE = 'freeversioncomparemessage';
export const UI_TEXT_FREE_VERSION_DOWNLOAD_MESSAGE = 'freeversiondownloadmessage';
export const UI_TEXT_FREE_VERSION_PLOT_MESSAGE = 'freeversionplotmessage';
export const UI_TEXT_LIMIT_SELECTION_TITLE = 'limitselectiontitle';
export const UI_TEXT_LIMIT_SELECTION_MESSAGE = 'limitselectionmessage';
export const UI_TEXT_LIMIT_FAVORITES_TITLE = 'limitfavoritestitle';
export const UI_TEXT_LIMIT_FAVORITES_MESSAGE = 'limitfavoritesmessage';
export const UI_TEXT_FEEDBACK_SENT_MESSAGE = 'feedbacksentmessage';
export const UI_TEXT_FEEDBACK_ERROR_MESSAGE = 'feedbackerrormessage';
export const UI_TEXT_CURRENT_VERSION_INCLUDES = 'currentversionincludes';
export const UI_TEXT_FREE_VERSION_FEATURES = 'freeversionfeatures';
export const UI_TEXT_VIEW_MATERIALS = 'viewmaterials';
export const UI_TEXT_ACCESS_TO_PLOTS = 'accesstoplots';
export const UI_TEXT_COMPARE_MATERIAL_PLOTS = 'comparematerialplots';
export const UI_TEXT_DOWNLOAD_CAE_MODELS = 'downloadcaemodels';
export const UI_TEXT_PREMIUM_VERSION_FEATURES = 'premiumversionfeatures';
export const UI_TEXT_GET_ALL_FEATURES = 'getallfeatures';
export const UI_TEXT_SUBMIT = 'submit';
export const UI_TEXT_OPTIONAL_MESSAGE = 'optionalmessage';
export const UI_TEXT_CLOSE = 'close';
export const UI_TEXT_OK = 'ok';
export const UI_TEXT_LOAD = 'load';
export const UI_TEXT_DELETE = 'delete';
export const UI_TEXT_SAVE_SEARCH_CRITERIA_AS = 'savesearchcriteriaas';
export const UI_TEXT_SAVE_SEARCH_CRITERIA = 'savesearchcriteria';
export const UI_TEXT_SAVE_CRITERIA_MESSAGE = 'savecriteriamessage';
export const UI_TEXT_DONT_SAVE = 'dontsave';
export const UI_TEXT_SEARCH_MATERIALS = 'searchmaterials';
export const UI_TEXT_SELECT_UNIT_SYSTEM = 'selectunitsystem';
export const UI_TEXT_UNIT_SYSTEM = 'unitsystem';
export const UI_TEXT_FILTER_TAG = 'filtertag';
export const UI_TEXT_VISIT_MBASE = 'visitmbase';
export const UI_TEXT_VALUE_RANGE = 'valuerange';
export const UI_TEXT_CANNOT_CONVERT = 'cannotconvert';
export const UI_TEXT_NO_COLUMNS_MATCH_FILTER = 'nocolumnsmatchfilter';
export const UI_TEXT_NAME = 'name';
export const UI_TEXT_SEARCH_INPUT = 'searchinput';
export const UI_TEXT_RANGE = 'range';
export const UI_TEXT_NO_FIELDS_MATCH_FILTER = 'nofieldsmatchfilter';
export const UI_TEXT_SHOWING_RESULTS_FOR = 'showingresultsfor';
export const UI_TEXT_FILTER_NAME_AND_COUNT_MORE = 'filternameandcountmore';
export const UI_TEXT_NO_MATERIALS_FOUND = 'nomaterialsfound';
export const UI_TEXT_OVERLAY = 'overlay';
export const UI_TEXT_SCATTER_PLOT = 'scatterplot';
export const UI_TEXT_PROPERTY_SELECTION = 'propertyselection';
export const UI_TEXT_SCATTER_PLOT_NO_DATA = 'scatterplotnodata';
export const UI_TEXT_ENG_TRUE_STRESS_STRAIN_PLACEHOLDER = 'engtruestresstrainplaceholder';
export const UI_TEXT_INCLUDE_GENERIC_DATA = 'includegenericdata';
export const UI_TEXT_STRESS_LIMIT_PLACEHOLDER = 'stresslimitplaceholder';
export const UI_TEXT_TEMPERATURE_PLACEHOLDER = 'temperatureplaceholder';
export const UI_TEXT_ENCRYPTED = 'encrypted';
export const UI_TEXT_POLAR_CHART = 'polarchart';
export const UI_TEXT_CURVE_PLACEHOLDER = 'curve';
export const UI_TEXT_RESET = 'reset';
export const UI_TEXT_HIDE = 'hide';
export const UI_TEXT_POLAR_CHART_MESSAGE = 'polarchartconstrain';
export const UI_TEXT_POLAR_PRPERTIES_MESSAGE = 'polarpropertiesconstrain';
export const UI_TEXT_SCATTER_PLOT_TITLE = 'scatterplottitle';
export const UI_TEXT_DOWMLOAD_MESSAGE = 'downloadmessage';
export const UI_TEXT_SKIP_LANDING = 'skiplanding';
export const UI_TEXT_NO_DIAGRAMS_AVAILABLE = 'nodiagramsavailable';
export const UI_TEXT_INVALID_DATA = 'invaliddata';
export const UI_TEXT_SCATTER_PLOT_MAKE_SELECTIONS = 'scatterplotmakeselections';
export const UI_TEXT_RESULT_SUMMARY = 'resultsummary';
export const UI_TEXT_COMPOUND = 'compound';
export const UI_TEXT_PERCENTAGE_BY_WEIGHT = 'percentagebyweight';
export const UI_TEXT_TOTAL = 'total';
export const UI_TEXT_MACHINE_TOOL = 'machinetool';
export const UI_TEXT_FILLERS_ADDITITVES = 'fillersaddititives';
export const UI_TEXT_MBASE_GENERIC = 'mbasegeneric';
export const UI_TEXT_COUNTRY = 'country';
export const UI_TEXT_IMPACT_ASSESSMENT = 'impactassessment';
export const UI_TEXT_CO2_TRACKER = 'co2tracker';
export const UI_TEXT_SAVE_GWP_AS = 'savegwpas';
export const UI_TEXT_SAVE_GWP = 'savegwp';
export const UI_TEXT_LOAD_GWP = 'loadgwp';
export const UI_TEXT_DOWNLOAD_SUCCESS = 'downloadsuccess';
export const UI_TEXT_DOWNLOAD_FAILED = 'downloadfailed';
export const UI_TEXT_ADD_TYPE = 'addtype';
export const UI_TEXT_SELECT_TYPE = 'selecttype';
export const UI_TEXT_SAVE_GWP_MESSAGE = 'savegwpmessage';
export const UI_TEXT_NO_DRIVE_LICENSE = 'nodrivelicense';
export const UI_TEXT_NO_DRIVE_LICENSE_PLACEHOLDER = 'nodrivelicenseplaceholder';
export const UI_TEXT_SHARE_TEXT = 'sharetext';
export const UI_TEXT_SEND_VIA_EMAIL = 'sendviaemail';
export const UI_TEXT_COPY_TO_CLIPBOARD = 'copytoclipboard';
export const UI_TEXT_LINK_COPIED_TO_CLIPBOARD = 'linkcopiedtoclipboard';
export const UI_TEXT_ADD_TO_COMPARE = 'addtocompare';
export const UI_TEXT_CLEAR_FAVORITES = 'clearfavorites';
export const UI_TEXT_SHARE_FAVORITES = 'sharefavorites';
export const UI_TEXT_LOAD_FAVORITE_LIST = 'loadfavoritelist';
export const UI_TEXT_SAVE_FAVORITE_LIST = 'savefavoritelist';
export const UI_TEXT_SHARE_FAVORITES_TEXT = 'sharefavoritestext';
export const UI_TEXT_COMPARE_RESULTS_MESSAGE = 'compareresultsmessage';
export const UI_TEXT_FAVORITE_MATERIALS = 'favoritematerials';
export const UI_TEXT_MATERIALS_SIMILAR_TO = 'materialssimilarto';
export const UI_TEXT_SAVE_TABLE_PROPERTY_PROFILE = 'savetablepropertyprofile';
export const UI_TEXT_LOAD_TABLE_PROPERTY_PROFILE = 'loadtablepropertyprofile';
export const UI_TEXT_LOAD_TABLE_PROFILE_BUTTON = 'loadtableprofilebutton';
export const UI_TEXT_SELECT_PROFILE = 'selectprofile';
export const UI_TEXT_PROFILE = 'profile';
export const UI_TEXT_OVERWRITE = 'overwrite';
export const UI_TEXT_NOTHING_SAVED_YET = 'nothingsavedyet';
export const UI_TEXT_BACK = 'back';
export const UI_TEXT_DEFINE_THRESHOLD = 'definethreshold';
export const UI_TEXT_COPY_SUCCESS_MESSAGE = 'copysuccess';

export function getUIText(key, texts, data = null, defaulttext = null) {
  if (defaulttext === null) defaulttext = key;
  return texts && texts[key] ? processSubstitution(texts[key], data) : defaulttext;
}
