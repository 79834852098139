/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

import { transfer2MathjsUnit } from '../utils/units';
import { getWebsocketClient } from '../websocket';

const mathjs = require('mathjs');



const getGenericHeader = () => {
  return {};
};

function getTagArray(tags) {
  let tagMap = new Map();

  if (tags) {
    let tagInputArray = tags.split(',');
    if (tagInputArray && tagInputArray.length > 0) {
      for (let tag of tagInputArray) {
        let tagKeyValue = tag.split('-');
        if (tagKeyValue && tagKeyValue.length > 1) {
          if (tagMap.has(tagKeyValue[0])) {
            tagMap.get(tagKeyValue[0]).push(parseInt(tagKeyValue[1]));
          } else {
            tagMap.set(tagKeyValue[0], [parseInt(tagKeyValue[1])]);
          }
        }
      }
    }
  }

  return Array.from(tagMap.values());
}

const compareFeatureArrays = (source, target) => {
  return source.every((v) => target.includes(v));
};

async function getRanges(
  qs,
  searchCatalogs,
  unitSystem,
  appContent = null,
  filterData = null,
  previousFilterData = null,
  ranges = null,
  storedTags = []
) {
  let spts = new Set();

  const mapChildren = (children) => {
    for (let child of children) {
      if (child.type === 'Spt') {
        spts.add(child.vkey);
      }
      if (child.children && child.children.length > 0) {
        mapChildren(child.children);
      }
    }
  };

  if (searchCatalogs && searchCatalogs.length > 0) {
    mapChildren(searchCatalogs[0].children);
  }

  const auth = this.getAuth();
  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  const url = `/mmdc/product/ranges?content=${content}`;

  let propfilter = [];
  let featfilter = [];
  let diafilter = [];
  let idfilter = [];
  let similar = null;

  if (filterData && filterData.new) {
    propfilter = filterData.propfilter;
    diafilter = filterData.diafilter;
    featfilter = filterData.featfilter;
    idfilter = filterData.idfilter;
    similar = filterData.similar;
  }

  let refinement = true;
  let containsAllPreviousFeatures = false;

  if (previousFilterData && previousFilterData.propfilter) {
    for (let previousPropfilter of previousFilterData.propfilter) {
      let foundMatch = false;

      for (let propfilter of filterData.propfilter) {
        if (previousPropfilter.vkey === propfilter.vkey) {
          if (previousPropfilter.text) {
            if (previousPropfilter.text === propfilter.text) {
              foundMatch = true;
            }
          } else if (previousPropfilter.min && previousPropfilter.max) {
            if (
              propfilter.min &&
              Number(propfilter.min) >= Number(previousPropfilter.min) &&
              propfilter.max &&
              Number(propfilter.max) <= Number(previousPropfilter.max)
            ) {
              foundMatch = true;
            }
          } else if (previousPropfilter.min) {
            if (propfilter.min && Number(propfilter.min) >= Number(previousPropfilter.min)) {
              foundMatch = true;
            }
          } else if (previousPropfilter.max) {
            if (propfilter.max && Number(propfilter.max) <= Number(previousPropfilter.max)) {
              foundMatch = true;
            }
          }

          break;
        }
      }

      if (!foundMatch) {
        refinement = false;
      }
    }
  } else {
    refinement = false;
  }

  let newTags = qs.tags ? qs.tags.split(',') : [];

  if (newTags && storedTags) {
    containsAllPreviousFeatures = compareFeatureArrays(storedTags, newTags);
  }

  if (previousFilterData && previousFilterData.featfilter && previousFilterData.featfilter.length > 0) {
    if (!compareFeatureArrays(previousFilterData.featfilter, filterData.featfilter)) {
      refinement = false;
    }
  }

  if (previousFilterData && previousFilterData.diafilter && previousFilterData.diafilter.length > 0) {
    if (!compareFeatureArrays(previousFilterData.diafilter, filterData.diafilter)) {
      refinement = false;
    }
  }

  if (refinement && ranges && ranges.Spt && containsAllPreviousFeatures) {
    spts = new Set();
    for (let [key, value] of Object.entries(ranges.Spt)) {
      if (value && value[0] !== null && value[1] !== null && !isNaN(key) && !isNaN(parseFloat(key))) {
        spts.add(Number(key));
      }
    }
  }

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {
      propfilter: propfilter,
      numprops: Array.from(spts),
      diafilter: diafilter,
      featfilter: featfilter ? featfilter.concat(getTagArray(qs.tags)) : [],
      textfilter: [qs.pattern ? decodeWithPercent(qs.pattern) : ''],
      polymerfilter: '',
      producerfilter: '',
      tablefilter: '',
      facetprops: '',
      unitsystem: parseInt(unitSystem),
      idfilter: idfilter,
      similar: similar,
    },
  });

  return data;
}

async function getFeatureCount(qs, unitSystem, filterData = null, appContent = null) {
  const auth = this.getAuth();
  let content = '209';
  if (appContent) {
    content = appContent.content;
  }
  const url = `/mmdc/product/qualfeaturecount?content=${content}`;

  let propfilter = [];
  let featfilter = [];
  let diafilter = [];
  let idfilter = [];
  let similar = null;

  if (filterData && !filterData.new) {
    for (let rule of filterData.rules) {
      if (rule.leftField.type === 'number') {
        rule.right = rule.right.replace('%', '');
        const suppliedValue = mathjs.unit(transfer2MathjsUnit(rule.right));
        let convertedResult =
          rule.leftField.siUnit && rule.leftField.siUnit !== '%'
            ? suppliedValue.to(transfer2MathjsUnit(rule.leftField.siUnit)).toNumber()
            : suppliedValue;

        if (rule.operator === '>') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), min: convertedResult.toString() });
        } else if (rule.operator === '<') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), max: convertedResult.toString() });
        } else if (rule.operator === '=') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), min: convertedResult.toString(), max: convertedResult.toString() });
        }
      } else if (rule.leftField.type === 'string') {
        if (rule.operator === '=') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), text: rule.right, exact: true });
        } else if (rule.operator === 'LIKE') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), text: rule.right, exact: false });
        }
      } else if (rule.leftField.type === 'Feature') {
        featfilter.push([rule.leftField.vkey]);
      } else if (rule.leftField.type === 'Mpt') {
        diafilter.push(rule.leftField.vkey);
      }
    }
  } else if (filterData && filterData.new) {
    propfilter = filterData.propfilter;
    featfilter = filterData.featfilter;
    diafilter = filterData.diafilter;
    idfilter = filterData.idfilter;
    similar = filterData.similar;
  }

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {
      propfilter: propfilter,
      numfilter: '',
      diafilter: diafilter,
      featfilter: featfilter ? featfilter.concat(getTagArray(qs.tags)) : [],
      textfilter: [qs.pattern ? decodeWithPercent(qs.pattern) : ''],
      polymerfilter: '',
      producerfilter: '',
      tablefilter: '',
      facetprops: '',
      unitsystem: parseInt(unitSystem),
      idfilter: idfilter,
      similar: similar,
    },
  });

  return data;
}

async function saveSearchCriteria(criteria, appContent) {
  const auth = this.getAuth();

  let content = '209';

  if (appContent && appContent.content) {
    content = appContent.content;
  }

  const url = `/mmdc/criteria?content=${content}`;

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {
      criteria,
    },
  });

  return data;
}

async function removeSearchCriteria(ids) {
  const auth = this.getAuth();
  const url = `/mmdc/criteria/remove`;

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {
      ids,
    },
  });

  return data;
}

async function getSearchCriteria(appContent, format = null, global = false) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
    withCredentials: true,
  };

  let content = '209';

  if (appContent && appContent.content) {
    content = appContent.content;
  }

  let url = `/mmdc/criteria?content=${content}`;

  if (format) {
    url += `&format=${format}`;
  }

  if (global) {
    url += '&global=true';
  }

  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function saveUserPreferences(preferences, content) {
  const auth = this.getAuth();
  const url = `/mmdc/user/prefs`;

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {
      preferences,
      content: content,
    },
  });

  return data;
}

async function removeUserPreferences(ids) {
  const auth = this.getAuth();
  const url = `/mmdc/user/prefs/remove`;

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {
      ids,
    },
  });

  return data;
}

async function getUserPreferences(content) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let url = `/mmdc/user/prefs?content=${content}`;

  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function checkAppAccess(appContent, appName) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };
  const content = appContent || '209';
  let apps = ['Altair Material Data Center'];
  if (appName && typeof appName === 'string') {
    apps = [appName];
  } else if (appName && typeof appName === 'object') {
    apps = appName;
  }

  const url = `/mmdc/user/checkAppAccess?content=${content}&appnames=${apps.join(',')}`;

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function getTableColumns(qs, appContent, language, unitSystem) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }
  //TODO replace with tag url for materials
  //Get Application Root
  let url = `/mmdc/catalog/readCatalog?node_key=0&depth=10&type=Section&usemask=107&info=1&content=${content}&lang=${language}&unitsystem=${unitSystem}`;

  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getScatterData(appContent, language, unitSystem, x, y, filterData, qs) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }
  //TODO replace with tag url for materials
  //Get Application Root
  let url = `/mmdc/product/scatterdata?content=${content}&lang=${language}&unitsystem=${unitSystem}&x=${x}&y=${y}`;
  let propfilter = [];
  let featfilter = [];
  let diafilter = [];
  let idfilter = [];

  if (filterData && filterData.new) {
    propfilter = filterData.propfilter;
    featfilter = filterData.featfilter;
    diafilter = filterData.diafilter;
    idfilter = filterData.idfilter;
  }

  const body = {
    propfilter: propfilter,
    featfilter: featfilter ? featfilter.concat(getTagArray(qs.tags)) : [],
    textfilter: [qs.pattern ? decodeWithPercent(qs.pattern) : ''],
    diafilter: diafilter,
    idfilter: idfilter,
    polymerfilter: '',
    producerfilter: '',
    unitsystem: parseInt(unitSystem),
  };

  const { data } = await axios.post(url, body, reqConfig);

  return data;
}

async function getAvailLanguages(qs, appContent, language) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/catalog/availLanguages?content=${content}&lang=${language}`;

  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getClassifications(qs, appContent, language, unitSystem) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  //TODO replace with tag url for materials
  //Get Application Root
  let url = `/mmdc/catalog/readCatalog?node_key=0&depth=10&type=Section&usemask=103&content=${content}&info=1&lang=${language}&unitsystem=${unitSystem}`;

  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getTexts(language, appContent) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  //TODO replace with tag url for materials
  //Get Application Root
  let url = `/mmdc/catalog/uitext?lang=${language}&content=${content}`;

  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getCatalogs(qs, appContent, language, unitSystem) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/catalog/readCatalog?node_key=0&depth=10&type=Section&usemask=105&content=${content}&info=1&lang=${language}&unitsystem=${unitSystem}`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getSearchCatalog(qs, appContent, language, unitSystem) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/catalog/readCatalog?node_key=0&depth=10&type=Section&usemask=108&content=${content}&info=1&lang=${language}&unitsystem=${unitSystem}`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getUnits(qs, appContent, language) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let url = `/mmdc/catalog/readCatalog?node_key=0&depth=10&type=Section&usemask=100&content=0&info=1&lang=${language}`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getNotifications() {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let url = `/mmdc/notification`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function dismissNotification(id) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let url = `/mmdc/notification/remove?id=${id}`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getCompareCatalog(qs, appContent, language, unitSystem) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/catalog/readCatalog?node_key=0&nrepph=1&depth=10&type=Section&usemask=109&content=${content}&info=1&lang=${language}&unitsystem=${unitSystem}`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getContentDefinitions() {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  //let url = `/content/content-definitions?contentDefinitions=Material,MaterialCardLaw2,MaterialCardLaw36,MaterialChemicalComposition,MaterialDesignation,MaterialFatigueAxialLoadingProperty,MaterialFatigueBendingLoadingProperty,MaterialFatigueTesting,MaterialFatigueTorsionalLoadingProperty,MaterialJohnsonCookModel,MaterialMechanicalAxialLoadingProperty,MaterialMechanicalTesting,MaterialMechanicalTorsionalLoadingProperty,MaterialTested,Provider,Quality,Software,Type`;
  let url = `/content/content-definitions/derived?merged=true&contentDefinitions=Material,MaterialProperty,MaterialGeneral,MaterialModel,MaterialTesting,MaterialCard,Tag`;
  const { data } = await axios.get(url, reqConfig);
  let cdefs = [];
  for (const item of data) {
    cdefs.push(item.cdef);
    if (item.derivedCdefs && item.derivedCdefs.length > 0) {
      for (const derivedCdef of item.derivedCdefs) {
        cdefs.push(derivedCdef.cdef);

        if (derivedCdef.derivedCdefs && derivedCdef.derivedCdefs.length > 0) {
          for (const _derivedCdef of derivedCdef.derivedCdefs) {
            cdefs.push(_derivedCdef.cdef);
          }
        }
      }
    }
  }
  return cdefs;
}

async function getDerivedContentDefinitions(contentType) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let url = `/content/content-definitions/derived?merged=false&contentDefinitions=` + contentType;

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function getMaterialDatasheet(materialId, qs, appContent, language, unitSystem, hash, appCatalogId = null) {
  const auth = this.getAuth();

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  if (appCatalogId) {
    content = appCatalogId;
  }

  const url = `/mmdc/bblocks/datasheet/${materialId}?unitsystem=${unitSystem}&content=${content}&lang=${language}`;

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: { hash: hash },
  });

  return data;
}

async function getMaterialDetails(materialId, qs, appContent) {
  const auth = this.getAuth();

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  const url = `/mmdc/product/datasheet/${materialId}?unitsystem=11&content=${content}`;

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {},
  });

  return data;
}

async function getDetails(materialIds) {
  const auth = this.getAuth();
  const url = `/materials/details/bulk`;

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {
      ids: materialIds,
    },
  });

  return data;
}

async function getCompareDetails(materialIds, appContent, language, unitSystem, sort = null) {
  const auth = this.getAuth();

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/product/compare?unitsystem=${unitSystem}&content=${content}&lang=${language}`;

  if (sort) {
    url += '&sort=' + sort;
  }

  const { data } = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    data: {
      ids: materialIds,
    },
  });

  return data;
}

async function getLandingBuildingBlocks(view, appContent, language, unitSystem) {
  const auth = this.getAuth();

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/bblocks/page?name=${view}&unitsystem=${unitSystem}&content=${content}&lang=${language}`;

  if (view === '404') {
    url = `/mmdc/bblocks/page?name=${view}&content=0`;
  }

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function fetchPlots(temperature, reductionFactor, stressLimit, unit, language, appContent) {
  const auth = this.getAuth();

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  // TODO get correct stress limit from backend after backend is updated
  // const url = `/mmdc/product/convertdiagram/?diaKey=${temperature.data.diaKey}&legend=${temperature.data.legend}&reduction=${reductionFactor.key}&stressLimit=58&unitSystem=${unit.key}&lang=${language}`;
  const url = `/mmdc/product/convertdiagram/?diaKey=${temperature.data.diaKey}&legend=${temperature.data.legend}&reduction=${reductionFactor}&stresslimit=${stressLimit}&unitsystem=${unit}&lang=${language}&content=${content}`;
  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function getDiagram(id, unit, language, appContent) {
  const auth = this.getAuth();

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  // TODO get correct stress limit from backend after backend is updated
  // const url = `/mmdc/product/convertdiagram/?diaKey=${temperature.data.diaKey}&legend=${temperature.data.legend}&reduction=${reductionFactor.key}&stressLimit=58&unitSystem=${unit.key}&lang=${language}`;
  const url = `/mmdc/product/caediagram/?key=${id}&unitsystem=${unit}&lang=${language}&content=${content}`;
  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function downloadMaterialCard(
  id,
  model,
  solver,
  unit,
  startNumber,
  moistureState,
  qs,
  reductionFactor,
  temperature,
  temperatureUnitSystem,
  poissonsRatio,
  engTrueStressStrain,
  stressLimit,
  includeGeneric,
  encrypted,
  appContent,
  websocket = null,
  diagram,
  dest
) {
  const auth = this.getAuth();

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  const templates = [];
  if (model.children) {
    for (let child of model.children) {
      if (child.vtype === 'Textblock') {
        templates.push(child.vkey);
      }
    }
  }

  const url = `/mmdc/product/caecard/${id}/${model.key}?unitsystem=${unit.key}${startNumber ? '&startNumber=' + startNumber : ''}${
    reductionFactor ? '&reduction=' + reductionFactor : ''
  }${typeof temperature !== 'undefined' ? '&temperature=' + temperature : ''}${
    temperatureUnitSystem ? '&temperatureUnitSystem=' + temperatureUnitSystem : ''
  }${poissonsRatio ? '&poissonsRatio=' + poissonsRatio : ''}${
    engTrueStressStrain ? '&engTrueStressStrain=' + engTrueStressStrain.key : ''
  }${stressLimit ? '&stressLimit=' + stressLimit : ''}${includeGeneric ? '&includeGeneric=' + includeGeneric : ''}${
    encrypted ? '&encrypt=' + encrypted : ''
  }${moistureState ? '&moist=' + moistureState.key : ''}&content=${content}${
    templates.length > 0 ? '&templates=' + templates.join(',') : ''
  }${dest ? '&dest=' + dest : ''}${typeof diagram !== 'undefined' ? '&diagram=' + diagram : ''}`;

  return axios({
    url: url,
    method: 'GET',
    headers: getGenericHeader(auth),
    responseType: dest === 'drive' ? 'application/json' : 'blob', // important
  }).then((response) => {
    if (response && response.headers['content-disposition']) {
      let contentDisposition = response.headers['content-disposition'];
      let fileName = 'Unknown';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      // try to find if the websocket is available
      if (!websocket) {
        websocket = getWebsocketClient();
      }

      if (websocket && websocket.send) {
        let fileReader = new FileReader();
        fileReader.onload = function () {
          // action is used to idenifify in the unity plugin the response came for which action
          // action = download: single material download, when sending back json
          let res = {
            success: true,
            action: 'download',
            id: response.headers.id,
            name: response.headers.name,
            version: response.headers.version,
            amdcId: response.headers.amdcid,
            data: fileReader.result,
          };
          // send the download response based on the response type set by the plugin
          if (sessionStorage.getItem('unity-response') === 'json') {
            websocket.send(JSON.stringify(res));
          } else if (window.location.pathname.includes('caeplugin/amm')) {
            res = {
              success: true,
              action: 'download',
              // caeexport: fileReader.result,
              data: JSON.stringify({ success: true, caeexport: fileReader.result }),
            };
            websocket.send(JSON.stringify(res));
          } else {
            websocket.send(fileReader.result);
          }
        };

        fileReader.onerror = function () {
          websocket.send(
            JSON.stringify({
              success: false,
              message: 'Error reading the export',
            })
          );
          console.log('Error on reading the file.');
        };

        fileReader.readAsText(response.data);
      } else {
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    return response;
  });
}

/**
 * external multiple CAE card download in single solver file. This is called from the plugin
 * when the `caecard` websocket action is requested
 * @param {Object} payload parameters for downloading
 */
async function downloadMaterialCardV1(payload) {
  const auth = this.getAuth();
  let url = '/mmdc/api/v1/caecard';
  let responseType = payload.dest === 'drive' ? 'application/json' : 'blob';
  if (payload.responseType) {
    responseType = 'application/json';
  }
  return axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    responseType: responseType,
    data: payload,
  }).then((response) => {
    // useful for the clients requesting json output rather than a file ex. plugins
    if (typeof response.data === 'string') {
      try {
        response.data = JSON.parse(response.data);
        response = { ...response, ...response.data };
        response.data = response.body || response.data;
      } catch (e) {
        //don't update the response
      }
    }
    if (responseType === 'application/json') {
      if (response.headers['x-ratelimit-limit']) {
        response.requestLimit = response.headers['x-rate-limit-limit'];
        response.requestLimitRemaining = response.headers['x-rate-limit-remaining'];
        response.requestLimitResetTime = response.headers['x-rate-limit-reset'];
      }

      delete response.config;
      delete response.body;
      delete response.headers;
      delete response.request;
      delete response.statusText;
      delete response.status;
      return response;
    }

    // response is blob type
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    let fileName = 'Unknown';
    let contentDisposition = response && response.headers['content-disposition'];
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
    }

    // for unity plugin integration.
    if (window.websocketclient) {
      let fileReader = new FileReader();
      fileReader.onload = function () {
        console.log(fileReader.result);
        // meta is used to idenifify in the unity plugin if this was multiple material download or
        // meta = 1: multi- material download
        let res = {
          action: 'caeexport',
          data: fileReader.result,
          success: false,
          error: response.headers.error,
        };
        try {
          // trying to parse the result to check if it is  Json
          let result = JSON.parse(fileReader.result);
          res = {
            success: result.success,
            error: response.headers.error,
            message: result.message,
            data: result.data || '',
          };
        } catch (error) {
          // JSON parsing not successful
        } finally {
          window.websocketclient.send(JSON.stringify(res));
        }
      };
      fileReader.readAsText(response.data);
      return;
    } else {
      // downloading the file
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
}

/**
 * Imports the material data using external api. This is currently used via websocket action for unity integration.
 * @param {object} payload std material json
 * @returns {object} status of the import
 */
async function uploadMaterialJson(payload) {
  const auth = this.getAuth();
  let url = '/api/api/v1/product/import';
  const result = await axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    responseType: 'application/json',
    data: payload,
  });
  return JSON.parse(result.data);
}

async function downloadPDF(id, name, appContent, dispatch, language, unitSystem, section = 'pdf', data = null, comment = null) {
  let content = '209';
  const auth = this.getAuth();
  if (appContent) {
    content = appContent.content;
  }

  let filename = 'material.pdf';

  if (name) {
    filename = name.replace(/[^a-zA-Z0-9]/g, '') + '.pdf';
  }
  section = section || 'pdf';
  let url = `/mmdc/product/datasheet/pdf/${id}/${content}/${language}/${unitSystem}/${filename}`;
  let queryAdded = false;
  if (appContent && appContent.dbname && !appContent.standarddb) {
    url += '?amdc-db=' + appContent.dbname;
    queryAdded = true;
  }
  if (queryAdded) {
    url += '&section=' + section;
  } else {
    url += '?section=' + section;
  }

  if (data || (appContent && appContent.user && appContent.user.licenseType === 'named')) {
    axios({
      url: url,
      method: 'POST',
      headers: getGenericHeader(auth),
      contentType: 'text/plain',
      responseType: 'blob', // important
      data: { svg: data, comment: comment },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      if (document.getElementById('clte-container')) {
        document.getElementById('clte-container').appendChild(link);
        link.click();
        document.getElementById('clte-container').removeChild(link);
      } else if (document.getElementById('ltha1-container')) {
        document.getElementById('ltha1-container').appendChild(link);
        link.click();
        document.getElementById('ltha1-container').removeChild(link);
      } else {
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      dispatch({
        type: 'PDF_DOWNLOAD_WAITING',
        isWaiting: false,
        id: id,
      });
    });
  } else {
    window.open(url, '_blank').focus();

    dispatch({
      type: 'PDF_DOWNLOAD_WAITING',
      isWaiting: false,
      id: id,
    });
  }
}

async function favoriteAction(url, payload, favoriteList, appContent, language, unitSystem) {
  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let newPayload = JSON.parse(JSON.stringify(payload));
  for (let [key, value] of Object.entries(newPayload)) {
    if (value === '%idlist%') {
      newPayload[key] = JSON.parse(JSON.stringify(favoriteList));
    } else if (value === '%content%') {
      newPayload[key] = content;
    } else if (value === '%unitsystem%') {
      newPayload[key] = unitSystem;
    } else if (value === '%language%') {
      newPayload[key] = language;
    }
  }

  openWindowWithPost(url, newPayload);
}

function openWindowWithPost(url, data) {
  var form = document.createElement('form');
  form.target = '_blank';
  form.method = 'POST';
  form.action = url;
  form.style.display = 'none';

  for (var key in data) {
    if (Array.isArray(data[key])) {
      for (let element of data[key]) {
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = key + '[]';
        input.value = element;
        form.appendChild(input);
      }
    } else {
      let input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

async function downloadImpactAssessmentPDF(
  activeId,
  appContent,
  language,
  unitSystem,
  compounds,
  machines,
  country,
  total,
  co2PlotData,
  gwpPlotData,
  co2svg,
  gwpsvg
) {
  let content = '209';
  const auth = this.getAuth();
  if (appContent) {
    content = appContent.content;
  }

  let filename = 'ImpactAssessment.pdf';

  let url = `/mmdc/toolbox/impactassessment/pdf/${activeId}/${content}/${language}/${unitSystem}/${filename}`;
  if (appContent && appContent.dbname && !appContent.standarddb) {
    url += '?amdc-db=' + appContent.dbname;
  }

  axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    contentType: 'text/plain',
    responseType: 'blob', // important
    data: {
      data: { compounds: compounds, machines: machines, country: country, total: total },
      svg: { co2PlotData: { ...co2PlotData, svg: co2svg }, gwpPlotData: { ...gwpPlotData, svg: gwpsvg } },
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

async function downloadXLS(id, appContent, language, unitSystem) {
  let content = '209';
  const auth = this.getAuth();
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/product/diagram/${id}/${content}/${language}/${unitSystem}`;

  axios({
    url: url,
    method: 'GET',
    headers: getGenericHeader(auth),
    contentType: 'text/plain',
    responseType: 'blob', // important
  }).then((response) => {
    if (response && response.headers['content-disposition']) {
      let contentDisposition = response.headers['content-disposition'];
      let fileName = 'Unknown';

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
}

async function exportMaterials(materialIds, applicationType, applicationName) {
  const auth = this.getAuth();

  const url = `/materials/export?applicationType=${applicationType}&applicationName=${applicationName}`;

  axios({
    url: url,
    method: 'POST',
    headers: getGenericHeader(auth),
    responseType: 'blob', // important
    data: materialIds,
  }).then((response) => {
    const fileName = getFileName(response.headers['content-disposition']);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

async function getMaterials({
  qs,
  tableColumns,
  pageSize = 30,
  offset = 0,
  filterData = null,
  sort,
  sortdir,
  appContent,
  view,
  language,
  preferences,
  unitSystem,
}) {
  const auth = this.getAuth();

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/bblocks/table?content=${content}&lang=${language}`;

  if (view === 'tiles') {
    url = `/mmdc/bblocks/tiles?content=${content}&lang=${language}`;
  }

  const tablefilter = [];

  if (preferences && preferences.tableProps && preferences.tableProps.length > 0) {
    preferences.tableProps.sort((a, b) => a.order - b.order);

    for (let tableProp of preferences.tableProps) {
      if (tableProp.id !== 17 || !tableProp.use_mask || !tableProp.use_mask.length) {
        tablefilter.push(tableProp.type + '-' + tableProp.id);
      } else {
        tablefilter.push(tableProp.type + '-' + tableProp.id + '-' + tableProp.use_mask.join('-'));
      }
    }
  } else if (tableColumns && tableColumns.length > 0 && tableColumns[0].children.length > 0) {
    let defaultTableColumns = null;
    for (let tableColumnEntry of tableColumns[0].children) {
      if (tableColumnEntry.use_mask.includes(212)) {
        defaultTableColumns = tableColumnEntry;
        break;
      }
    }
    for (const child of defaultTableColumns.children) {
      if (child.vkey !== 17 || !child.use_mask || !child.use_mask.length) {
        tablefilter.push(`${child.type}-${child.vkey}`);
      } else {
        tablefilter.push(`${child.type}-${child.vkey}-${child.use_mask.join('-')}`);
      }
    }
  }

  //let filteredTags = qs.tags ? decodeURIComponent(qs.tags).split(',') : [];
  /*
	let tagsArray = [];

	classifications.forEach(classification => {
		let currentTag = { Id: classification.id, filter: false };
		if (filteredTags.includes(classification.id)) {
			currentTag.filter = true;
		}
		tagsArray.push(currentTag);
	});

	let pattern = qs.pattern ? decodeURIComponent(qs.pattern) : undefined;
	*/

  let propfilter = [];
  let featfilter = [];
  let diafilter = [];
  let idfilter = [];
  let similar = null;

  if (filterData && !filterData.new) {
    for (let rule of filterData.rules) {
      if (rule.leftField.type === 'number') {
        rule.right = rule.right.replace('%', '');
        const suppliedValue = mathjs.unit(transfer2MathjsUnit(rule.right));
        let convertedResult =
          rule.leftField.siUnit && rule.leftField.siUnit !== '%'
            ? suppliedValue.to(transfer2MathjsUnit(rule.leftField.siUnit)).toNumber()
            : suppliedValue;

        if (rule.operator === '>') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), min: convertedResult.toString() });
        } else if (rule.operator === '<') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), max: convertedResult.toString() });
        } else if (rule.operator === '=') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), min: convertedResult.toString(), max: convertedResult.toString() });
        }
      } else if (rule.leftField.type === 'string') {
        if (rule.operator === '=') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), text: rule.right, exact: true });
        } else if (rule.operator === 'LIKE') {
          propfilter.push({ vkey: rule.leftField.vkey.toString(), text: rule.right, exact: false });
        }
      } else if (rule.leftField.type === 'Feature') {
        featfilter.push([rule.leftField.vkey]);
      } else if (rule.leftField.type === 'Mpt') {
        diafilter.push(rule.leftField.vkey);
      }
    }
  } else if (filterData && filterData.new) {
    propfilter = filterData.propfilter;
    featfilter = filterData.featfilter;
    diafilter = filterData.diafilter;
    idfilter = filterData.idfilter;
    similar = filterData.similar;
  }

  const body = {
    propfilter: propfilter,
    featfilter: featfilter ? featfilter.concat(getTagArray(qs.tags)) : [],
    textfilter: [qs.pattern ? decodeWithPercent(qs.pattern) : ''],
    diafilter: diafilter,
    polymerfilter: '',
    producerfilter: '',
    tablefilter: tablefilter,
    limit: pageSize,
    offset: offset,
    sort: sort,
    sortdir: sortdir,
    unitsystem: parseInt(unitSystem),
    idfilter: idfilter,
    similar: similar,
  };

  /*
		const body = {
				"contentType": "Material",
				"pattern": qs.pattern || "*",
				// "filter": "o.attributes.name == \"adminuser\"",
				// "timeFilter": "lastDay",
				"projectFilter": (qs.filter)?qs.filter:"none",
				"tags": (qs.tags)?decodeURIComponent(qs.tags).split(','):[],
				"sortKey": (qs.sort === "created")? "modifiedTime":"attributes.name",
				"sortDirection": (qs.order === "-1")?"desc":"asc",
				"returnCounts": true,
				"offset": 0,
				"pageSize": 10000
		}*/

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);

  let newTags = qs.tags ? qs.tags.split(',') : [];
  data.storedTags = newTags;

  return data;
}

function decodeWithPercent(pattern) {
  return decodeURIComponent(pattern.replace(/%/g, '%25')).replace(/\$p\$/g, '%').replace(',', ' ');
}
async function graphSearch(filterData, pageSize = 30, offset = 0) {
  const auth = this.getAuth();
  const url = `/search/search?searchInView=true&cache=true&returnCounts=true&limit=` + pageSize + `&offset=` + offset;

  const body = {
    filterData: filterData,
    pagination: {
      offset: offset,
      limit: pageSize,
    },
  };

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);

  return data;
}

async function getProviders() {
  const auth = this.getAuth();
  const url = `/search/search`;
  const body = {
    filterData: {
      combinator: 'OR',
      rules: [
        {
          leftField: {
            key: 'contentType',
            path: ['system'],
            type: 'string',
            displayName: 'contentType',
          },
          operator: '=',
          right: 'MaterialProvider',
        },
      ],
    },
  };

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);
  return data.entities;
}

async function getUnitSystems() {
  const auth = this.getAuth();
  const url = `/search/search`;
  const body = {
    filterData: {
      combinator: 'OR',
      rules: [
        {
          leftField: {
            key: 'contentType',
            path: ['system'],
            type: 'string',
            displayName: 'contentType',
          },
          operator: '=',
          right: 'UnitSystem',
        },
      ],
    },
  };

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);
  return data.entities;
}

async function getSIUnitSystem() {
  const auth = this.getAuth();
  const url = `/search/search`;
  const body = {
    filterData: {
      combinator: 'AND',
      rules: [
        {
          leftField: {
            key: 'contentType',
            path: ['system'],
            type: 'string',
            displayName: 'contentType',
          },
          operator: '=',
          right: 'UnitSystem',
        },
        {
          leftField: {
            key: 'name',
            path: ['attributes'],
            type: 'string',
            displayName: 'name',
          },
          operator: '=',
          right: 'SI',
        },
      ],
    },
  };

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);
  return data.entities;
}

async function getSolvers() {
  const auth = this.getAuth();
  const url = `/content/contents/search?contentType=Solver`;
  const body = {};

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);
  return data;
}

async function heartbeat(body) {
  const auth = this.getAuth();
  const url = `/mmdc/license/heartbeat`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);
  return data;
}

async function checkin(body) {
  const auth = this.getAuth();
  const url = `/mmdc/license/checkin`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);
  return data;
}

async function getMaterial(id) {
  const auth = this.getAuth();
  const url = `/materials/${id}`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function getSolversForMaterials(ids) {
  const auth = this.getAuth();
  const url = `/materials/${ids}/solvers`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function getApplicationsForMaterials(ids) {
  const auth = this.getAuth();
  const url = `/materials/${ids}/applications`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function importCheck(appContent) {
  const auth = this.getAuth();

  const url = `/mmdc/product/uicheck?content=${appContent.content}`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function getAppContent(name) {
  const auth = this.getAuth();

  let url = `/mmdc/catalog/appcontent`;

  if (name != null) {
    url += `?name=${name}`;
  }

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.get(url, reqConfig);

  if (data && data.sessionId) {
    axios.defaults.headers.common['sessionId'] = data.sessionId;
  }

  if (data.dbname && data.dbname.length > 0) {
    axios.defaults.headers.common['amdc-db'] = data.dbname;
  }

  return data;
}

async function uiCheck() {
  const auth = this.getAuth();
  const url = `/materials/ui/check`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function getPlot(id) {
  const auth = this.getAuth();
  const url = `/file/files/${id}/read`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.get(url, reqConfig);
  return data;
}

async function getPlotJsonMap(entityMap) {
  const auth = this.getAuth();
  let plotJsonMap = new Map();

  for (const [key, value] of entityMap) {
    let id = value.fileId;

    const url = `/file/files/${id}/read`;

    const reqConfig = {
      headers: getGenericHeader(auth),
    };

    const { data } = await axios.get(url, reqConfig);
    plotJsonMap.set(key, data);
  }
  return plotJsonMap;
}

async function importMaterialsFromFile(file, importProvider, importSourceTemplate) {
  const auth = this.getAuth();
  const url = `/materials/importFromFile?providerName=${importProvider.text}&sourceTemplate=${importSourceTemplate.key}`;

  const formData = new FormData();
  formData.append('materialFile', file);

  const reqConfig = {
    headers: { loginUserId: auth.userId, 'content-type': 'multipart/form-data' },
  };

  const { data } = await axios.post(url, formData, reqConfig);
  return data;
}

async function importMaterials() {
  const auth = this.getAuth();
  const url = `/materials/import`;

  const body = {
    entities: [
      {
        id: 'general1',
        system: {
          contentType: 'Material',
        },
        attributes: {
          name: 'Mat1',
          source: 'csv',
          subSource: 'MATDAT Materials Properties Database www.matdat.com',
          description: 'this is a material imported from CSV file',
        },
      },
      {
        id: 'mechanical1',
        system: {
          contentType: 'MaterialMechanical',
        },
        attributes: {
          youngsmodules: {
            unit: 'Nmm^-2',
            value: 214000,
          },
          poissionratio: {
            value: 0.4,
          },
          density: {
            unit: 'Tmm^-3',
            value: 7.85e-9,
          },
        },
      },
      {
        id: 'fatigue1',
        system: {
          contentType: 'MaterialFatigue',
        },
        attributes: {
          cys: {
            unit: 'Nmm^-2',
            value: 286,
          },
          tl: {
            unit: 'Nmm^-2',
            value: 8087,
          },
          csc: {
            unit: 'Nmm^-2',
            value: 999,
          },
        },
      },
      {
        id: 'general2',
        system: {
          contentType: 'Material',
        },
        attributes: {
          name: 'Mat2',
          source: 'csv',
          subSource: 'MATDAT Materials Properties Database www.matdat.com',
          description: 'this is a material imported from CSV file',
        },
      },
      {
        id: 'mechanical2',
        system: {
          contentType: 'MaterialMechanical',
        },
        attributes: {
          youngsmodules: {
            unit: 'Nmm^-2',
            value: 210000,
          },
          poissionratio: {
            value: 0.3,
          },
          density: {
            unit: 'Tmm^-3',
            value: 7.85e-9,
          },
        },
      },
      {
        id: 'chemical1',
        system: {
          contentType: 'MaterialChemical',
        },
        attributes: {
          c: {
            value: 222,
          },
          si: {
            value: 8087,
          },
          mn: {
            value: 999,
          },
        },
      },
    ],
    relations: [
      {
        fromContentType: 'Material',
        fromId: 'general1',
        toContentType: 'MaterialMechanical',
        toId: 'mechanical1',
        relationType: 'mechanical',
      },
      {
        fromContentType: 'Material',
        fromId: 'general1',
        toContentType: 'MaterialFatigue',
        toId: 'fatigue1',
        relationType: 'fatigue',
      },
      {
        fromContentType: 'Material',
        fromId: 'general1',
        toContentType: 'Tag',
        toId: '5d634395040d3a4d647535e9',
        relationType: 'provider',
      },
      {
        fromContentType: 'Material',
        fromId: 'general1',
        toContentType: 'Tag',
        toId: '5d634327040d3a4d647535ca',
        relationType: 'unit',
      },
      {
        fromContentType: 'Material',
        fromId: 'general1',
        toContentType: 'Tag',
        toId: '5d63437e040d3a4d647535e2',
        relationType: 'type',
      },
      {
        fromContentType: 'Material',
        fromId: 'general2',
        toContentType: 'MaterialMechanical',
        toId: 'mechanical2',
        relationType: 'mechanical',
      },
      {
        fromContentType: 'Material',
        fromId: 'general2',
        toContentType: 'MaterialChemical',
        toId: 'chemical1',
        relationType: 'chemical',
      },
      {
        fromContentType: 'Material',
        fromId: 'general2',
        toContentType: 'Tag',
        toId: '5d63438d040d3a4d647535e6',
        relationType: 'provider',
      },
      {
        fromContentType: 'Material',
        fromId: 'general2',
        toContentType: 'Tag',
        toId: '5d634344040d3a4d647535d1',
        relationType: 'unit',
      },
      {
        fromContentType: 'Material',
        fromId: 'general2',
        toContentType: 'Tag',
        toId: '5d634379040d3a4d647535df',
        relationType: 'type',
      },
    ],
  };

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, body, reqConfig);
  return data;
}

async function qtExportMaterials(materialIds, applicationType, applicationName, qtHandler) {
  const auth = this.getAuth();

  const url = `/materials/export?applicationType=${applicationType}&applicationName=${applicationName}`;

  qtHandler('onMaterialDownload', {
    onMaterialDownload: {
      url: url,
      headers: getGenericHeader(auth),
      data: materialIds,
    },
  });
}

async function getPolarData(payload, language, unitSystem, appContent) {
  const auth = this.getAuth();

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  const url = `/mmdc/product/spiderdata?content=${content}&lang=${language}&unitsystem=${unitSystem}`;

  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  const { data } = await axios.post(url, payload, reqConfig);
  return data;
}

async function getPolarCatalog(qs, appContent, language, unitSystem) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/catalog/readCatalog?node_key=0&depth=10&type=Section&usemask=19&content=${content}&info=1&lang=${language}&unitsystem=${unitSystem}`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getScatterCatalog(qs, appContent, language, unitSystem) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/catalog/readCatalog?node_key=0&depth=10&type=Section&usemask=17&content=${content}&info=1&lang=${language}&unitsystem=${unitSystem}`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function getImpactAssessmentData(qs, appContent, language, unitSystem) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }

  let url = `/mmdc/toolbox/impactassessment/getdata?content=${content}&info=1&lang=${language}&unitsystem=${unitSystem}`;
  const { data } = await axios.get(url, reqConfig);

  return data;
}

function getFileName(disposition) {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName = null;
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName;
}

async function exportCompareExcel(appContent, mode, language, unitSystem, matKeys) {
  const auth = this.getAuth();
  const reqConfig = {
    headers: getGenericHeader(auth),
    contentType: 'text/plain',
    responseType: 'blob',
  };

  let content = '209';
  if (appContent) {
    content = appContent.content;
  }
  let url = `/mmdc/product/compare/export/${mode}?content=${content}&lang=${language}&units=${unitSystem}&matKeys=${matKeys.join(',')}`;
  return axios
    .get(url, reqConfig)
    .then((response) => {
      if (response.headers['content-disposition']) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const filename = getFileName(response.headers['content-disposition']);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename || 'CompareExport.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export default {
  getAppContent,
  getAvailLanguages,
  getMaterial,
  getPlot,
  getPlotJsonMap,
  getMaterials,
  getMaterialDetails,
  getMaterialDatasheet,
  getCompareDetails,
  getProviders,
  getSolvers,
  getUnitSystems,
  getSIUnitSystem,
  getUnits,
  getSolversForMaterials,
  getApplicationsForMaterials,
  getFeatureCount,
  getRanges,
  getClassifications,
  getContentDefinitions,
  getCatalogs,
  getSearchCatalog,
  getCompareCatalog,
  getScatterData,
  getTableColumns,
  getTexts,
  getDerivedContentDefinitions,
  getLandingBuildingBlocks,
  getSearchCriteria,
  fetchPlots,
  saveSearchCriteria,
  removeSearchCriteria,
  getUserPreferences,
  saveUserPreferences,
  removeUserPreferences,
  importMaterials,
  graphSearch,
  getDetails,
  importMaterialsFromFile,
  downloadMaterialCard,
  downloadMaterialCardV1,
  uploadMaterialJson,
  downloadPDF,
  downloadXLS,
  exportMaterials,
  importCheck,
  uiCheck,
  qtExportMaterials,
  getPolarData,
  getPolarCatalog,
  getScatterCatalog,
  checkAppAccess,
  getImpactAssessmentData,
  downloadImpactAssessmentPDF,
  exportCompareExcel,
  favoriteAction,
  getNotifications,
  getDiagram,
  dismissNotification,
  checkin,
  heartbeat,
};
